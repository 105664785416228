import ar from "~/locales/ar";
import en from "~/locales/en";
import fa from "~/locales/fa";
// import es from "~/locales/es";
// import fr from "~/locales/fr";
// import hi from "~/locales/hi";
// import it from "~/locales/it";
// import ja from "~/locales/ja";
// import ko from "~/locales/ko";
// import nl from "~/locales/nl";
// import pt from "~/locales/pt";
// import ru from "~/locales/ru";
// import sv from "~/locales/sv";
// import th from "~/locales/th";
// import tr from "~/locales/tr";
// import vi from "~/locales/vi";
// import zh from "~/locales/zh";

// This is the list of languages your application supports
export const supportedLngs = [
  "en",
  "ar",
  "fa",
  // "pt",
  // "es",
  // "fr",
  // "de",
  // "it",
  // "hi",
  // "ja",
  // "ko",
  // "ru",
  // "tr",
  // "zh",
  // "vi",
  // "th",
  // "sv",
  // "nl",
];

// This is the language you want to use in case
// if the user language is not in the supportedLngs
export const fallbackLng = "en";

// The default namespace of i18next is "translation", but you can customize it
// here
export const defaultNS = "translation";

export const resources = {
  en: { translation: en },
  ar: { translation: ar },
  fa: { translation: fa },
  // pt: { translation: pt },
  // fr: { translation: fr },
  // es: { translation: es },
  // nl: { translation: nl },
  // ru: { translation: ru },
  // hi: { translation: hi },
  // it: { translation: it },
  // ja: { translation: ja },
  // ko: { translation: ko },
  // sv: { translation: sv },
  // th: { translation: th },
  // tr: { translation: tr },
  // vi: { translation: vi },
  // zh: { translation: zh },
};

export const LANGUAGES: { lng: string; name: string; local: string }[] = [
  { lng: "en", name: "English", local: "us" },
  { lng: "ar", name: "Arabic", local: "ar" },
  { lng: "fa", name: "Persian", local: "ir" },
  // { lng: "pt", name: "Portuguese", local: "pt" },
  // { lng: "es", name: "Spanish", local: "es" },
  // { lng: "fr", name: "French", local: "fr" },
  // { lng: "de", name: "German", local: "de" },
  // { lng: "it", name: "Italian", local: "it" },
  // { lng: "hi", name: "Hindi", local: "in" },
  // { lng: "ja", name: "Japanese", local: "jp" },
  // { lng: "ko", name: "Korean", local: "kr" },
  // { lng: "ru", name: "Russian", local: "ru" },
  // { lng: "tr", name: "Turkish", local: "tr" },
  // { lng: "zh", name: "Chinese", local: "cn" },
  // { lng: "vi", name: "Vietnamese", local: "vi" },
  // { lng: "th", name: "Thai", local: "th" },
  // { lng: "sv", name: "Swedish", local: "sv" },
  // { lng: "nl", name: "Dutch", local: "nl" },
];
