export default {
  title: "HidSim: شراء أرقام افتراضية للتحقق من الرسائل القصيرة",
  description:
    "استقبال الرسائل القصيرة عبر الإنترنت باستخدام أرقام هواتف افتراضية والتسجيل بشكل مجهول في أي خدمة",
  og: {
    title: "HidSim: خدمة التحقق من الرسائل القصيرة",
    description:
      "استقبال الرسائل القصيرة عبر الإنترنت باستخدام أرقام هواتف افتراضية والتسجيل بشكل مجهول في أي خدمة",
    site_name: "HidSim",
    buyVirtualNumbersFor: "شراء أرقام افتراضية لـ {{name}}",
  },
  login: "تسجيل الدخول",
  register: "تسجيل",
  profile: "الملف الشخصي",
  balance: "الرصيد",
  dangerZone: "منطقة الخطر",
  deleteAccount: "حذف الحساب",
  backToHome: "العودة إلى الصفحة الرئيسية",
  topUp: "شحن الرصيد",
  language: "اللغة",
  currency: "العملة",
  currencies: "العملات",
  settings: "الإعدادات",
  myReceipts: "إيصالاتي",
  support: "الدعم",
  chatOnline: "الدردشة عبر الإنترنت",
  downloadIosApp: "تحميل تطبيق iOS",
  downloadAndroidApp: "تحميل تطبيق Android",
  receipts: "الإيصالات",
  invoices: "الفواتير",
  logout: "تسجيل الخروج",
  buyVirtualNumbersFor: "شراء أرقام افتراضية لـ {{name}}",
  disconnected: "غير متصل",
  connecting: "جارٍ الاتصال",
  connected: "متصل",
  retry: "إعادة المحاولة",
  credit: "رصيد",
  credits: "أرصدة",
  coins: "عملات",
  coin_one: "{{count}} عملة",
  coin_other: "{{count}} عملات",
  services: "الخدمات",
  operations: "العمليات",
  pending: "قيد الانتظار",
  refunded: "تم الاسترداد",
  completed: "مكتمل",
  searchServices: "البحث عن الخدمات",
  searchCountryFor: "البحث عن دولة لـ {{name}}",
  default: "افتراضي",
  priceHighToLow: "السعر من الأعلى إلى الأدنى",
  priceLowToHigh: "السعر من الأدنى إلى الأعلى",
  qualityHighToLow: "الجودة من الأعلى إلى الأدنى",
  qualityLowToHigh: "الجودة من الأدنى إلى الأعلى",
  nothingIsHereYet: "لا يوجد شيء هنا بعد",
  okay: "حسنًا",
  done: "تم",
  myWallet: "محفظتي",
  copied: "تم النسخ",
  copy: "نسخ",
  numbersAvailable: "الأرقام المتاحة",
  noNumbersAvailable: "لا توجد أرقام متاحة",
  floating: "عائم",
  automatic: "تلقائي",
  findingCountry: "البحث عن دولة",
  letHidSimFind: "دع HidSim يجد",
  refund: "استرداد",
  sessions: "الجلسات",
  activeSessions: "الجلسات النشطة",
  myDevices: "أجهزتي",
  devices: "الأجهزة",
  revoke: "إلغاء",
  installAndroidApp: "تثبيت تطبيق Android",
  installIosApp: "تثبيت تطبيق iOS",
  pagination: {
    previous: "السابق",
    next: "التالي",
  },
  readMore: "اقرأ المزيد",
  showMore: "عرض المزيد",
  didNotReceiveCode: "لم تتلق الرمز؟",
  providers: {
    p1: "المشغل الأول",
    p2: "المشغل الثاني",
    p3: "المشغل الثالث",
  },
  grades: {
    unknown: "غير معروف",
    poor: "ضعيف",
    moderate: "متوسط",
    standard: "قياسي",
  },
  qualityTheBetterTheHigher:
    "الجودة: كلما كانت جودة الاستقبال على خدمة ورقم معين أفضل، كانت الجودة أعلى.",
  theCountOfAvailableNumbersOperatorIndicated:
    "عدد الأرقام المتاحة المعروض هو تقدير مقدم من المشغل. قد لا يكون دقيقًا. نوصي بتجربة الخدمة لمعرفة ما إذا كانت تعمل. إذا لم تعمل، سيتم إصدار استرداد.",
  errors: {
    emailAddressAlreadyTaken: "عنوان البريد الإلكتروني مأخوذ بالفعل",
    "number.base": "يرجى إدخال رقم",
    "number.empty": "لا يمكن أن يكون هذا الحقل فارغًا",
    "any.required": "هذا الحقل مطلوب",
    "any.only": "القيمة المدخلة غير مسموح بها",
    "string.base": "يرجى ملء الحقل",
    "string.empty": "لا يمكن أن يكون هذا الحقل فارغًا",
    "0": "لا يوجد اتصال بالإنترنت",
    "0_message": "حدثت مشكلة في الاتصال بالإنترنت، يرجى المحاولة مرة أخرى",
    "401": "غير مصرح",
    "401_message":
      "غير مصرح لك بالوصول إلى هذا القسم، يرجى الاتصال بمسؤول الموقع",
    "500": "خطأ في الاتصال",
    "500_message": "حدثت مشكلة في الاتصال بالإنترنت، يرجى المحاولة مرة أخرى",
    "404": "غير موجود",
    "404_message":
      "لم نتمكن من العثور على ما تبحث عنه، هل أنت ضائع؟ انقر على الزر أدناه للعودة إلى الصفحة الرئيسية",
    "409": "رمز منتهي الصلاحية",
    "409_message":
      "رمزك الأخير منتهي الصلاحية، يرجى إعادة تحميل الصفحة ومتابعة المعاملة",
    "429": "طلبات كثيرة جدًا",
    "429_message": "طلبات كثيرة جدًا، حاول مرة أخرى بعد {{seconds}} ثانية",
  },
  faq: "الأسئلة الشائعة",
  home: {
    title: "HidSim؛ الاتصال الأول بالخصوصية",
    description:
      "تخيل أنك تريد إنشاء حسابات لاستخدام الشبكات الاجتماعية مثل Instagram وTwitter وFacebook ومواقع وتطبيقات مختلفة. ومع ذلك، لإكمال عملية التسجيل، تحتاج إلى إدخال رقم هاتف والتحقق منه عبر الرسائل القصيرة. خاصة عندما لا يكون من الممكن أو لا ترغب في استخدام رقمك الشخصي، أو تحتاج إلى إنشاء عدد كبير من الحسابات لأغراض شخصية أو تجارية، يوفر لك لوحة الأرقام الافتراضية من HidSim القدرة على استقبال الأرقام من أكثر من 160 دولة حول العالم، بما في ذلك الدول الأوروبية والآسيوية، بتكلفة منخفضة جدًا. يمكن لهذه الأرقام استقبال الرسائل القصيرة التي تحتوي على رموز التفعيل لإنشاء حسابات على أي خدمة.",
    benefits: {
      instantDelivery: "تسليم فوري",
      secureAndAnonymous: "آمن ومجهول",
      onlyPayForReceivedCodes: "ادفع فقط مقابل الرموز المستلمة",
      visaAndCreditCardAccepted: "يتم قبول Visa وCredit Card",
      cryptoAccepted: "يتم قبول العملات المشفرة",
    },
    faq: {
      q1: "كيف يمكنني الحصول عليه؟",
      a1: "يمكنك شحن رصيدك باستخدام العملات المشفرة. بمجرد الشحن، اختر الخدمة والدولة التي تريدها، ثم اشترِ الأرقام الافتراضية برصيدك.",

      q2: "ماذا يحدث إذا لم أتلق الرمز؟",
      a2: "إذا لم تتلق رمزًا على رقمك، فقد يكون هناك عدة أسباب، مثل مشاكل الشبكة أو تأخير في الخدمة المرسلة. في هذه الحالة، يمكنك إلغاء التفعيل والمحاولة برقم جديد؛ سيتم استرداد الأموال تلقائيًا إلى رصيدك. قد يساعد استخدام VPN أو وكيل من بلد الرقم في تحسين نجاح التسليم.",

      q3: "كم من الوقت تكون الأرقام متاحة لي؟",
      a3: "عند شراء رقم، يكون متاحًا للاستخدام مرة واحدة، وتملكه لمدة 15 دقيقة. خلال هذه الفترة، أدخل الرقم في الخدمة المطلوبة. سيراقب HidSim الرمز الوارد ويعرضه في علامة التبويب التفعيلات داخل تطبيقنا. بمجرد استلام الرمز، يصبح الرقم غير نشط.",

      q4: "لماذا تم حظر رقمي من قبل خدمة؟",
      a4: "قد يتم حظر رقمك بسبب أنماط الاستخدام أو مشاكل عنوان IP أو تصنيفه كروبوت. لتجنب ذلك، استخدم اسمًا وصورة ملف شخصي واقعيين، وشارك في محادثات حقيقية، وتجنب الحصول على الأرقام بسرعة كبيرة، وفكر في استخدام VPN يتطابق مع بلد الخدمة.",
      q5: "لماذا لا يمكنني تلقي رمز في خدمات Telegram؟",
      a5: "لتسجيل الدخول الأول في Telegram، تجنب استخدام أجهزة الكمبيوتر المحمولة أو الأجهزة اللوحية. استخدم هاتفًا محمولًا لإنشاء حسابك في البداية. بعد إكمال تسجيل الدخول الأول، يمكنك نقل حسابك إلى أي أجهزة أخرى تفضلها.",

      q6: "هل يمكنني تلقي رمز لرقم أكثر من مرة؟",
      a6: "لا، لا يمكن تلقي رمز لنفس الرقم أكثر من مرة. كل رقم افتراضي للاستخدام مرة واحدة فقط، وبعد استلام الرمز، يصبح غير نشط.",

      qOther: "هل لديك أي أسئلة أخرى؟",
      aOther:
        'تحدث مع فريق دعم العملاء الممتاز لدينا على <0 href="https://t.me/HidSimsupport" target="_blank" style="color: #6B96BD;">Telegram</0> واحصل على إجابات في غضون دقائق، على مدار الساعة طوال أيام الأسبوع. <1 href="https://t.me/HidSimsupport" target="_blank" style="color: #6B96BD;">اتصل على Telegram</1>',
    },
  },
  navbar: {
    myAccount: "حسابي",
    startNow: "ابدأ الآن",
    login: "تسجيل الدخول",
    services: "الخدمات",
    profile: "الملف الشخصي",
    myNumbers: "أرقامي",
  },
  operation: {
    creating: "جارٍ الإنشاء",
    pending: "نشط",
    completed: "مكتمل",
    no_number_refunded: "تم الاسترداد بسبب عدم وجود رقم",
    system_refunded: "تم الاسترداد تلقائيًا بواسطة النظام",
    user_refunded: "تم الاسترداد بواسطتك",
    waitingForMessage: "في انتظار الرسالة",
  },
  receipt: {
    purchase: "رقم تم شراؤه",
    refund_with_revoke: "استرداد مع إلغاء",
    refund_with_fraud_revoke: "استرداد مع إلغاء الاحتيال",
    refund_with_user_action: "استرداد مع إجراء المستخدم",
    refund_with_system_action: "استرداد مع إجراء النظام",
    system_freeze: "تم تجميد الرصيد",
    system_unfreeze: "تم تحرير الرصيد",
    system_fee: "رسوم",
  },
  invoice: {
    paid: "مدفوع",
    paid_over: "مدفوع أكثر",
    wrong_amount: "مبلغ خاطئ",
    process: "عملية",
    confirm_check: "تأكيد التحقق",
    wrong_amount_waiting: "انتظار المبلغ الخاطئ",
    check: "تحقق",
    fail: "فشل",
    cancel: "إلغاء",
    system_fail: "فشل النظام",
    refund_process: "عملية الاسترداد",
    refund_fail: "فشل الاسترداد",
    refund_paid: "تم دفع الاسترداد",
  },
  auth: {
    login: {
      "mobile.number": "رقم الهاتف المحمول",
      password: "كلمة المرور",
      "email.address": "عنوان البريد الإلكتروني",
      invalid: "البريد الإلكتروني أو كلمة المرور غير صحيحة",
    },
    register: {
      "mobile.number": "رقم الهاتف المحمول",
      "email.address": "عنوان البريد الإلكتروني",
      password: {
        hash: "كلمة المرور",
        confirmation: "تأكيد كلمة المرور",
      },
      profile: {
        name: {
          first: "الاسم الأول",
          last: "الاسم الأخير",
        },
      },
    },
    alreadyHaveAccount: "هل لديك حساب بالفعل؟",
    notHaveAccount: "ليس لديك حساب بعد؟",
    forgotYourPassword: "هل نسيت كلمة المرور؟",
    registerNow: "سجل الآن",
    loginToHidSim: "تسجيل الدخول إلى HidSim",
    registerToHidSim: "التسجيل في HidSim",
    enterYourCredentials: "أدخل بيانات الاعتماد الخاصة بك للمتابعة",
    backToHome: "العودة إلى الصفحة الرئيسية",
    byRegisteringYouAgreeToOurTermsOfService: `بالتسجيل، فإنك توافق على <2>شروط الخدمة</2> و <6>سياسة الخصوصية</6> الخاصة بنا.`,
  },
  landing: {
    nav: {},
  },
  modals: {
    downloadNativeApplication: {
      howToDownload: "كيفية التحميل",
      androidDescription:
        "نحن متحمسون للإعلان عن أن إصدار Android من HidSim (مع دعم الشراء داخل التطبيق) أصبح الآن متاحًا لاختبار مغلق! 🎉 هذه فرصتك الحصرية لتكون من بين الأوائل الذين يجربون خدمة التحقق من الرسائل القصيرة الافتراضية لدينا ويساعدوننا في تحسين التطبيق قبل الإصدار الرسمي. للمشاركة، ما عليك سوى إرسال بريدك الإلكتروني في Google Play إلى دعم Telegram الخاص بنا",
      iosDescription:
        "ترقبوا إصدار iOS من HidSim، قريبًا! في هذه الأثناء، يمكنك الوصول إلى خدمة التحقق من الرسائل القصيرة الافتراضية الخاصة بنا عبر تطبيق الويب. للحصول على آخر التحديثات حول تطبيق iOS، تابعنا على Telegram. @HidSim",
      downloadAndroidApp: "تحميل تطبيق Android",
      downloadIosApp: "غير متاح بعد لنظام iOS",
    },
    revokeConfirmation: {
      title: "تأكيد الإلغاء",
      browser: "المتصفح",
      os: "نظام التشغيل",
      country: "الدولة",
      ip: "عنوان IP",
      discard: "تجاهل",
      pleaseWait: "يرجى الانتظار",
      revoke: "إلغاء",
    },
    addToHome: {
      title: "إضافة HidSim إلى الشاشة الرئيسية",
      ios: {
        one: "1. باستخدام <b>متصفح Safari</b> اضغط على المربع مع الفلاش الصادر مشابه لـ <img />",
        two: "2. ثم اضغط على <span key='0'>إضافة إلى الشاشة الرئيسية</span>",
        three:
          "3. يرجى الانتظار ثانية حتى يظهر الرمز والمعلومات ثم اضغط على زر <span>إضافة</span>.",
      },
      android: {
        one: "1. اضغط على <b>3 نقاط</b> في أعلى الشاشة بجوار شريط العنوان في <b>Chrome</b>.",
        two: "2. اضغط على <b>إضافة إلى الشاشة الرئيسية</b>.",
        three: "3. اضغط على خيار <b>تثبيت</b>.",
      },
    },
    init: {
      title: "مرحبًا بك في HidSim",
      subtitle: "اختر لغتك وعملتك للبدء",
      selectLanguage: "اختر اللغة",
      selectCurrency: "اختر العملة",
    },
    charge: {
      title: "شراء العملات",
      payWithCrypto: "الدفع بالعملات المشفرة",
      payWithCreditCard: "الدفع ببطاقة الائتمان",
      payWithIrLocalGateway: "الدفع بالريال",
      mostPopular: "الأكثر شيوعًا",
      otherPackages: "حزم أخرى",
      submitBtn:
        "<0>شراء </0><1>{{name}}</1><2> مقابل </2><3><0>{{symbol}}</0><1>{{price}}</1></3>",
      notAcceptable:
        "طريقة الدفع هذه غير متاحة بناءً على وحدة العملة الخاصة بك، يرجى تغيير وحدة العملة الخاصة بك أولاً.",
      useNativeApplications:
        "للدفع ببطاقات الائتمان والخصم، يرجى استخدام تطبيقاتنا الأصلية. روابط التحميل متاحة في صفحة الملف الشخصي، في حالة وجود أي مشكلة في الدفع، يرجى الاتصال بنا.",
    },
    orderConfirmation: {
      orderNumber: "رقم الطلب",
      refundPolicy:
        "في غضون 20 دقيقة إذا لم يتلق الرقم الرسائل القصيرة، سيتم إعادة التكلفة إلى الرصيد.",
      notEnoughCredits:
        "ليس لديك حاليًا أرصدة كافية. تحتاج إلى {{amount}} أرصدة إضافية لاستخدام هذا الرقم.",
      orderFor: "<0>طلب لـ</0><1>{{amount}}</1><2>رصيد</2>",
      pleaseWait: "يرجى الانتظار",
      addCredits: "إضافة أرصدة",
    },
    autoOrderConfirmation: {
      refundPolicy: `سيقوم HidSim تلقائيًا بالبحث عن الدولة المناسبة بشروطك المختارة، خلال هذا الوقت ليس من الضروري فتح صفحة التطبيق ويمكنك مغادرة البرنامج والعودة مرة أخرى.`,
      noProductFoundForThisRange:
        "لم يتم العثور على منتجات لهذا السعر، يرجى تغيير نطاق السعر الخاص بك.",
      upperLimit: "الحد الأعلى",
      lowerLimit: "الحد الأدنى",
      note: "سيتم تطبيق رسوم 10% على الطلبات التلقائية",
    },
    cancelOrderConfirmation: {
      refundNumber: "استرداد الرقم",
      refund: "استرداد",
      pleaseWait: "يرجى الانتظار",
      discard: "تجاهل",
    },
    deleteUserConfirmation: {
      deleteAccount: "حذف الحساب",
      areYouSure:
        "هل أنت متأكد أنك تريد حذف حسابك؟ هذا الإجراء لا رجعة فيه وسيؤدي إلى حذف جميع بياناتك.",
      delete: "حذف",
      pleaseWait: "يرجى الانتظار",
      discard: "تجاهل",
    },
    operationWhy: {
      whyNumberIsRefunded: {
        no_number_refunded: "لماذا تم استرداد الرقم بسبب عدم توفر الأرقام؟",
        system_refunded: "لماذا تم استرداد الرقم بسبب إجراء النظام؟",
        timeout_system_refunded: "لماذا تم استرداد الرقم؟",
        user_refunded: "تم الاسترداد بواسطة المستخدم",
      },
      no_number_refunded:
        "تتم تزويد أرقام المخزون لكل دولة بناءً على بيانات من شركات الاتصالات. ومع ذلك، في بعض الأحيان، وعلى الرغم من وجود المخزون، قد لا يتم تفعيل الرقم من قبل هذه الشركات لصالح HidSim، مما يؤدي إلى ظهور تحذير <لا يوجد رقم متاح>. تنشأ هذه المشكلة بسبب اضطرابات مع مقدمي خدمات الاتصالات الدولية، وهي خارجة عن سيطرة HidSim.",
      system_refunded:
        "بسبب خطأ في النظام، لم يتمكن الرقم الذي اشتريته من التفعيل. قام النظام تلقائيًا باسترداد المبلغ الذي دفعته. يمكنك المحاولة مرة أخرى بعد دقائق أو ساعات.",
      timeout_system_refunded:
        "بعد 15-20 دقيقة من عدم النشاط، يقوم النظام تلقائيًا باسترداد المبلغ إلى محفظتك.",
      user_refunded: "تم استرداد الرقم الذي اشتريته بواسطة المستخدم.",
    },
  },
};
